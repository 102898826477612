<template>
  <!-- form -->
  <div>
    <validation-observer
      ref="registerForm"
      v-slot="{ passes }"
    >
      <b-form
        class="auth-register-form mt-2"
        @submit.prevent="passes(handleSubmitForm)"
      >
        <div class="mb-2">
          <h1>Company Driver Details</h1>
        </div>
        <!-- company Name -->
        <b-form-group>
          <validation-provider
            v-slot="{ errors }"
            name="Company Name"
            vid="name"
          >
            <b-form-input
              id="name"
              v-model="form.name"
              :state="errors.length > 0 ? false : null"
              name="register-company"
              placeholder="Company Name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!-- Last Name -->
        <b-form-group>
          <validation-provider
            v-slot="{ errors }"
            name="Company Registration Number"
            vid="company_registration"
          >
            <b-form-input
              id="company_regno"
              v-model="form.company_registration"
              :state="errors.length > 0 ? false : null"
              name="register-companyregno"
              placeholder="Company Registration Number"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!-- Address1 -->
        <b-form-group>
          <validation-provider
            name="Address"
          >
            <vue-google-autocomplete
              id="map0"
              classname="form-control"
              placeholder="Enter your complete address here..."
              :country="['my', 'sg']"
              @placechanged="getAddressData"
            />
            <!-- <small class="text-danger">{{ errors[0] }}</small> -->
          </validation-provider>
        </b-form-group>
        <b-form-group
          label="Unit No."
        >
          <validation-provider
            v-slot="{ errors }"
            name="Unit No."
            vid="address.unit"
          >
            <b-form-input
              v-model="form.address.unit"
              :state="errors.length > 0 ? false:null"
              name="unit"
              placeholder="Unit No."
            />
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </b-form-group>
        <b-form-group>
          <validation-provider
            v-slot="{ errors }"
            name="Address Line 1"
            vid="address.address1"
          >
            <b-form-input
              id="address1"
              v-model="form.address.address1"
              :state="errors.length > 0 ? false : null"
              name="address1"
              placeholder="Address Line 1"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!-- Address2 -->
        <b-form-group>
          <validation-provider
            v-slot="{ errors }"
            name="Address Line 2"
            vid="address.address2"
          >
            <b-form-input
              id="address2"
              v-model="form.address.address2"
              :state="errors.length > 0 ? false : null"
              name="address2"
              placeholder="Address Line 2"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <div class="row">
          <div class="col-md-6">
            <!-- Address2 -->
            <b-form-group>
              <validation-provider
                v-slot="{ errors }"
                name="Postcode"
                vid="address.postcode"
              >
                <b-form-input
                  id="Postcode"
                  v-model="form.address.postcode"
                  :state="errors.length > 0 ? false : null"
                  name="Postcode"
                  placeholder="Postcode"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <!-- Area -->
            <b-form-group>
              <validation-provider
                v-slot="{ errors }"
                name="Area"
                vid="address.area"
              >
                <b-form-input
                  id="Area"
                  v-model="form.address.area"
                  :state="errors.length > 0 ? false : null"
                  name="Area"
                  placeholder="Area"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <validation-provider
              v-slot="{ errors }"
              name="state"
              vid="address.state"
            >
              <v-select
                v-model="form.address.state"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="text"
                :options="stateOption"
                placeholder="State"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </div>
          <div class="col-md-6 mb-1">
            <!-- Country -->
            <validation-provider
              v-slot="{ errors }"
              name="country"
              vid="address.country"
            >
              <v-select
                v-model="form.address.country"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="text"
                :options="[
                  {value: 'Malaysia', text: 'Malaysia'},
                  {value: 'Singapore', text: 'Singapore'}
                ]"
                placeholder="Country"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </div>
        </div>

        <!-- Person In Charge Name -->
        <b-form-group>
          <validation-provider
            v-slot="{ errors }"
            name="Person In Charge Name"
            vid="address.name"
          >
            <b-form-input
              v-model="form.address.name"
              class="mt-2"
              placeholder="Person In Charge Name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!-- Email Address -->
        <b-form-group>
          <validation-provider
            v-slot="{ errors }"
            name="Email Address"
            vid="email"
          >
            <b-form-input
              v-model="form.email"
              placeholder="Email Address"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <div class="row">
          <div class="col-md-12">
            <!-- Mobile Number -->
            <b-form-group>
              <validation-provider
                v-slot="{ errors }"
                name="Phone"
                vid="phone"
              >
                <b-form-input
                  v-model="form.phone"
                  round
                  :state="errors.length > 0 ? false : null"
                  name="Phone"
                  placeholder="Phone Number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
        </div>
        <div class="mb-2">
          <h1>Password</h1>
        </div>
        <!-- Enter Your Password-->
        <b-form-group>
          <validation-provider
            v-slot="{ errors }"
            name="password"
            vid="password"
          >
            <b-input-group>
              <b-form-input
                v-model="form.password"
                :state="errors.length > 0 ? false : null"
                :type="passwordFieldType"
                name="password"
                placeholder="Enter Your Password"
              />
              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="passwordToggleIcon"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Retype Your Password-->
        <b-form-group class="mb-2">
          <validation-provider
            v-slot="{ errors }"
            name="confirm password"
            vid="password_confirmation"
          >
            <b-input-group>
              <b-form-input
                v-model="form.password_confirmation"
                :state="errors.length > 0 ? false : null"
                :type="passwordFieldType"
                name="confirmPassword"
                placeholder="Retype Your Password"
              />
              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="passwordToggleIcon"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <div class="mb-2">
          <h1>Company Documents</h1>
        </div>
        <div class="row mb-2">
          <div class="col-md-12">
            <label for="">SSM Document <FeatherIcon
              size="20"
              class="hasCriteria"
              icon="InfoIcon"
              @click="showCriteria(1)"
            /></label>
            <validation-provider
              v-slot="{ errors }"
              name="SSM"
              vid="smart_ssm_document"
            >
              <!-- Styled -->
              <b-form-file
                id="ssm"
                v-model="smart_ssm_document"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                accept=".pdf"
                @change="handleUploadSSM"
              />
              <small
                v-if="uploadingSsm"
                id="check-validity-ssm"
                class="text-info"
              >Checking valididty</small>
              <small
                v-else
                class="text-danger"
              >{{ errors[0] }}</small>
            </validation-provider>
          </div>
        </div>
        <!-- <div class="row mb-2">
              <div class="col-md-12">
                <label>Company Director’s ID Card <FeatherIcon @click="showCriteria(2)" size="20" class="hasCriteria" icon="InfoIcon" /></label>

                <b-form-file
                  v-model="form.cdic"
                  :state="Boolean(form.cdic)"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                  accept="image/*"
                  ref="cdic_file"
                  @input="validate_cdic"
                />
              </div>
            </div> -->
        <div class="">
          <h1>Policy</h1>
        </div>
        <div class="mb-2">
          <!-- Policy-->
          <b-row>
            <!-- Driver SOP Policy -->
            <b-col cols="6">
              <b-form-group>
                <validation-provider
                  v-slot="{ errors }"
                  name="SOP Policy"
                  rules="required"
                >
                  <div
                    class="form-check form-check-inline"
                    style="font-size:11px"
                  >
                    <input
                      id="sp"
                      v-model="sp"
                      v-b-modal.modal-3
                      type="checkbox"
                      class="form-check-input"
                      true-value="yes"
                      false-value=""
                    >
                    I agree to &nbsp;<b-link v-b-modal.modal-3>
                      Driver SOP Policy
                    </b-link>
                  </div>
                  <br>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Data Privacy Policy -->
            <b-col cols="6">
              <b-form-group>
                <validation-provider
                  v-slot="{ errors }"
                  name="Data Privacy Policy"
                  rules="required"
                >
                  <div
                    class="form-check form-check-inline"
                    style="font-size:11px"
                  >
                    <input
                      id="dpp"
                      v-model="dpp"
                      v-b-modal.modal-4
                      type="checkbox"
                      class="form-check-input"
                      true-value="yes"
                      false-value=""
                    >
                    I agree to &nbsp;<b-link v-b-modal.modal-4>
                      Data Privacy Policy
                    </b-link>
                  </div>
                  <br>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!--  Privacy Terms Policy -->
            <b-col cols="6">
              <b-form-group>
                <validation-provider
                  v-slot="{ errors }"
                  name="Privacy Policy"
                  rules="required"
                >
                  <div
                    class="form-check form-check-inline"
                    style="font-size:11px"
                  >
                    <input
                      id="pp"
                      v-model="pp"
                      v-b-modal.modal-1
                      type="checkbox"
                      class="form-check-input"
                      true-value="yes"
                      false-value=""
                    >
                    I agree to &nbsp;<b-link v-b-modal.modal-1>
                      Privacy Terms Policy
                    </b-link>
                  </div>
                  <br>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Refund Policy -->
            <b-col cols="6">
              <b-form-group>
                <validation-provider
                  v-slot="{ errors }"
                  name="Refund Policy"
                  rules="required"
                >
                  <div
                    class="form-check form-check-inline"
                    style="font-size:11px"
                  >
                    <input
                      id="rp"
                      v-model="rp"
                      v-b-modal.modal-2
                      type="checkbox"
                      class="form-check-input"
                      true-value="yes"
                      false-value=""
                    >
                    I agree to &nbsp;<b-link v-b-modal.modal-2>
                      Refund Policy
                    </b-link>
                  </div>
                  <br>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Rules & Conduct Policy -->
            <b-col cols="12">
              <b-form-group>
                <validation-provider
                  v-slot="{ errors }"
                  name="Rules & Conduct Policy"
                  rules="required"
                >
                  <div
                    class="form-check form-check-inline"
                    style="font-size:11px"
                  >
                    <input
                      id="rcp"
                      v-model="rcp"
                      v-b-modal.modal-5
                      type="checkbox"
                      class="form-check-input"
                      true-value="yes"
                      false-value=""
                    >
                    I agree to &nbsp;<b-link v-b-modal.modal-5>
                      Rules & Conduct Policy
                    </b-link>
                  </div>
                  <br>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div>
          <b-button
            :disabled="loading"
            block
            type="submit"
            variant="danger"
          >
            {{ loading ? 'Please wait...' : 'Submit' }}
          </b-button>
        </div>
      </b-form>
    </validation-observer>

    <p class="text-center mt-2">
      <b-link :to="{ name: 'login' }">
        <feather-icon icon="ChevronLeftIcon" /> Back to login
      </b-link>
    </p>

    <!-- MODALS   -->
    <!-- SOP Policy  -->
    <b-modal
      id="modal-3"
      content-class="policy-modal"
      ok-only
      ok-title="Agree"
      size="lg"
      no-close-on-backdrop
      scrollable
      @ok="agree('sp')"
    >
      <b-card-text>
        <div class="container">
          <sop-policy />
        </div>
      </b-card-text>
    </b-modal>

    <!-- Golog Terms -->
    <b-modal
      id="modal-1"
      content-class="policy-modal"
      ok-only
      ok-title="Agree"
      size="lg"
      no-close-on-backdrop
      scrollable
      @ok="agree('pp')"
    >
      <b-card-text>
        <div class="container">
          <golog-terms />
        </div>
      </b-card-text>
    </b-modal>

    <!-- Refund Policy -->
    <b-modal
      id="modal-2"
      content-class="policy-modal"
      ok-only
      ok-title="Agree"
      size="lg"
      no-close-on-backdrop
      scrollable
      @ok="agree('rp')"
    >
      <b-card-text>
        <div class="container">
          <refund-policy />
        </div>
      </b-card-text>
    </b-modal>

    <!-- Data Policy -->
    <b-modal
      id="modal-4"
      content-class="policy-modal"
      ok-only
      ok-title="Agree"
      size="lg"
      no-close-on-backdrop
      scrollable
      @ok="agree('dpp')"
    >
      <b-card-text>
        <div class="container">
          <data-policy />
        </div>
      </b-card-text>
    </b-modal>

    <!-- Data Policy -->
    <b-modal
      id="modal-5"
      content-class="policy-modal"
      ok-only
      ok-title="Agree"
      size="lg"
      no-close-on-backdrop
      scrollable
      @ok="agree('rcp')"
    >
      <b-card-text>
        <div class="container">
          <rules-policy />
        </div>
      </b-card-text>
    </b-modal>

    <b-modal
      id="critModal"
      ref="critSsmModal"
      ok-only
      ok-title="OK"
      centered
      size="xl"
      title="Document Criteria"
    >
      <SmartTruckSsm />
    </b-modal>

    <b-modal
      id="critModal"
      ref="critNRICModal"
      ok-only
      ok-title="OK"
      centered
      size="xl"
      title="Document Criteria"
    >
      <DriverNric />
    </b-modal>
  </div>
</template>

<script>
import axios from '@axios'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import SmartTruckSsm from '@/components/criteria/SmartTruckSsm.vue'
import DriverNric from '@/components/criteria/DriverNric.vue'
import {
  BLink,
  BCardText,
  BForm,
  BButton,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import GologTerms from '@/components/GologTerms.vue'
import RefundPolicy from '@/components/RefundPolicy.vue'
import SopPolicy from '@/components/SopPolicy.vue'
import DataPolicy from '@/components/DataPolicy.vue'
import RulesPolicy from '@/components/RulesPolicy.vue'
import vSelect from 'vue-select'
import VueGoogleAutocomplete from 'vue-google-autocomplete'

export default {
  components: {
    SmartTruckSsm,
    DriverNric,
    VueGoogleAutocomplete,
    vSelect,
    GologTerms,
    SopPolicy,
    RefundPolicy,
    DataPolicy,
    RulesPolicy,
    // BSV
    BLink,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      loading: false,
      uploadingSsm: false,
      smart_ssm_document: null,
      value: [],
      sp: null,
      dpp: null,
      pp: null,
      rp: null,
      rcp: null,
      options: [
        { text: 'I agree to the Golog Terms and Condition', value: 'first' },
        { text: 'I agree to Refund Policy', value: 'second' },
        {
          text: 'I agree all the above information are valid',
          value: 'fourth',
        },
        { text: 'I agree to SOP policy', value: 'third' },
      ],

      form: {
        email: null,
        phone: null,
        name: null,
        company_registration: null,
        password: null,
        password_confirmation: null,
        driver_type: 1,
        smart_ssm_document: null,
        address: {
          unit: null,
          address1: null,
          address2: null,
          postcode: null,
          area: null,
          state: null,
          country: null,
          name: null,
        },
      },

      // validation rules
      required,
      stateOption: [
        { text: 'Kuala Lumpur', value: 'Kuala Lumpur' },
        { text: 'Perlis', value: 'Perlis' },
        { text: 'Kedah', value: 'Kedah' },
        { text: 'Pulau Pinang', value: 'Pulau Pinang' },
        { text: 'Perak', value: 'Perak' },
        { text: 'Pahang', value: 'Pahang' },
        { text: 'Kelantan', value: 'Kelantan' },
        { text: 'Terengganu', value: 'Terengganu' },
        { text: 'Selangor', value: 'Selangor' },
        { text: 'Negeri Sembilan', value: 'Negeri Sembilan' },
        { text: 'Melaka', value: 'Melaka' },
        { text: 'Johor', value: 'Johor' },
        { text: 'Sabah', value: 'Sabah' },
        { text: 'Sarawak', value: 'Sarawak' },
      ],
    }
  },

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },

  methods: {
    async handleSubmitForm() {
      this.loading = true
      const response = await axios.post('register/smarttruck', this.form)
      if (response.status === 422) {
        this.$refs.registerForm.setErrors(response.data.errors)
        this.$swal({
          icon: 'error',
          title: 'Validation Error',
          text: 'Please check required fields.',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        })
        this.loading = false
        return
      }

      if (response.status === 201) {
        this.$swal({
          icon: 'success',
          title: 'Success',
          text: 'Successfully Created An Account',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        }).then(res => {
          if (res) {
            this.$router.replace({ name: 'login' })
          }
        })
      }
      this.loading = false
    },

    async handleUploadSSM(event) {
      const { files } = event.target
      const formData = new FormData()
      formData.append('ssm', files[0])
      this.uploadingSsm = true
      const response = await axios.post('/upload-ssm', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      if (response.status === 200) {
        this.form.smart_ssm_document = response.data
        this.uploadingSsm = false
      }
    },

    agree(elementId) {
      const checkbox = document.getElementById(elementId)
      checkbox.checked = true

      switch (elementId) {
        case 'sp':
          this.sp = 'yes'
          break
        case 'pp':
          this.pp = 'yes'
          break
        case 'dpp':
          this.dpp = 'yes'
          break
        case 'rcp':
          this.rcp = 'yes'
          break
        default:
          this.rp = 'yes'
          break
      }
    },

    getAddressData(address, place) {
      let addressOne = ''

      if (Object.prototype.hasOwnProperty.call(address, 'street_number')) { addressOne += address.street_number }
      if (Object.prototype.hasOwnProperty.call(address, 'route')) { addressOne += ` ${address.route}` }

      this.form.address.address1 = addressOne
      this.form.address.postcode = (Object.prototype.hasOwnProperty.call(address, 'postal_code')) ? address.postal_code : null
      this.form.address.area = (Object.prototype.hasOwnProperty.call(address, 'locality')) ? address.locality : null
      this.form.address.country = address.country
      this.form.address.address2 = place?.vicinity

      const getstate = (Object.prototype.hasOwnProperty.call(address, 'administrative_area_level_1')) ? address.administrative_area_level_1 : null
      const setArea = this.stateOption.filter(state => state.value === getstate)

      if (!setArea.length) {
        const stateObj = { text: 'Singapore', value: 'Singapore' }
        if (!Object.prototype.hasOwnProperty.call(this.stateOption, this.stateOption.find(o => o.value === stateObj.value))) {
          this.stateOption.push(stateObj)
        }

        if (address.country === 'Malaysia' && address.administrative_area_level_1) {
          this.form.address.state = ''
        } else {
          this.form.address.state = this.stateOption.find(o => o.value === stateObj.value).value
        }
      } else {
        this.form.address.state = getstate
      }
    },

    showCriteria(docType) {
      return (docType === 1)
        ? this.$refs.critSsmModal.show()
        : this.$refs.critNRICModal.show()
    },

    closeCriteria(docType) {
      return docType === 1
        ? this.$refs.critSsmModal.hide()
        : this.$refs.critNRICModal.hide()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/pages/page-auth.scss';
.policy-modal header {
  display: none;
}
.hasCriteria {
    cursor: pointer !important;
}
.policy-modal footer {
  justify-content: center;
}
</style>
<style scoped>
  .auth-wrapper.auth-v1 .auth-inner {
  max-width: 600px;
}
</style>
