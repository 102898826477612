<template>
        <!-- form -->
    <div>
        <validation-observer ref="registerFormInd">
          <b-form
            class="auth-register-form mt-2"
            @submit.prevent="validationForm2"
          >
            <div class="mb-2">
              <h1>Driver Details</h1>
            </div>
           
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Driver Name"
                rules="required"
              >
                <b-form-input
                  id="Driver_name"
                  v-model="form.companyname"
                  :state="errors.length > 0 ? false : null"
                  name="register-company"
                  placeholder="Driver Full Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- Last Name -->
            <!-- Address1 -->
             <b-form-group>
              <validation-provider
                name="Address"
              >
                <vue-google-autocomplete 
                  id="map1" 
                  classname="form-control" 
                  placeholder="Enter your complete address here..." 
                  v-on:placechanged="getAddressData"
                  :country="['my', 'sg']"
                >
                </vue-google-autocomplete>
                <!-- <small class="text-danger">{{ errors[0] }}</small> -->
              </validation-provider>
            </b-form-group>
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Address Line 1"
                rules="required"
              >
                <b-form-input
                  id="address1"
                  v-model="form.address1"
                  :state="errors.length > 0 ? false : null"
                  name="address1"
                  placeholder="Address Line 1"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- Address2 -->
            <b-form-group>
              <validation-provider
                rules="required"
                #default="{ errors }"
                name="Address Line 2"
              >
                <b-form-input
                  id="address2"
                  v-model="form.address2"
                  :state="errors.length > 0 ? false : null"
                  name="address2"
                  placeholder="Address Line 2"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <div class="row">
              <div class="col-md-6">
                <!-- Address2 -->
                <b-form-group>
                  <validation-provider
                    rules="required"
                    #default="{ errors }"
                    name="Postcode"
                  >
                    <b-form-input
                      id="Postcode"
                      v-model="form.postcode"
                      :state="errors.length > 0 ? false : null"
                      name="Postcode"
                      placeholder="Postcode"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div class="col-md-6">
                <!-- Area -->
                <b-form-group>
                  <validation-provider
                    rules="required"
                    #default="{ errors }"
                    name="Area"
                  >
                    <b-form-input
                      id="Area"
                      v-model="form.area"
                      :state="errors.length > 0 ? false : null"
                      name="Area"
                      placeholder="Area"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div class="col-md-6">
                <validation-provider
                  rules="required"
                  #default="{ errors }"
                  name="state"
                >
                   <v-select
                        v-model="form.state"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="text"
                        :options="stateOption"
                        placeholder="State"
                    />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
              <div class="col-md-6 mb-1">
                <!-- Country -->
                <validation-provider
                  rules="required"
                  #default="{ errors }"
                  name="country"
                >
                  <v-select
                        v-model="form.country"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="text"
                        :options="[
                            {value: 'Malaysia', text: 'Malaysia'},
                            {value: 'Singapore', text: 'Singapore'}

                        ]"
                        placeholder="Country"
                    />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
            </div>

            <!-- Person In Charge Name -->
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Person In Charge Name"
                rules="required"
              >
                <b-form-input
                  v-model="form.incharge"
                  placeholder="Person In Charge Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- Email Address -->
            <b-form-group>
              <validation-provider
                rules="required|email"
                #default="{ errors }"
                name="Email Address"
              >
                <b-form-input
                  v-model="form.email"
                  placeholder="Email Address"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <div class="row">
              <div class="col-md-6">
                <!-- Mobile Number -->
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Phone"
                    rules="required|integer"
                  >
                    <b-form-input
                      v-model="form.mobile"
                      round
                      :state="errors.length > 0 ? false : null"
                      name="Phone"
                      placeholder="Phone Number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>

              <div class="col-md-6">
                <!-- Office Number-->

                <b-form-group class="mb-2">
                  <validation-provider
                    #default="{ errors }"
                    name="Office Number"
                  >
                    <b-form-input
                      :state="errors.length > 0 ? false : null"
                      name="Office Number"
                      placeholder="Office Number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
            </div>
            <div class="mb-2">
              <h1>Password</h1>
            </div>
            <!-- Enter Your Password-->
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="password"
                rules="required|min:8"
              >
                <b-input-group>
                  <b-form-input
                    v-model="form.password"
                    :state="errors.length > 0 ? false : null"
                    :type="passwordFieldType"
                    name="password"
                    placeholder="Enter Your Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Retype Your Password-->
            <b-form-group class="mb-2">
              <validation-provider
                #default="{ errors }"
                name="confirm password"
                rules="required|confirmed:password"
              >
                <b-input-group>
                  <b-form-input
                    v-model="form.confirm_password"
                    :state="errors.length > 0 ? false : null"
                    :type="passwordFieldType"
                    name="confirmPassword"
                    placeholder="Retype Your Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
         
          
            <div class="">
              <h1>Policy</h1>
            </div>
            <div class="mb-2">
              <!-- Policy-->
              <b-row>
                        <!-- Driver SOP Policy -->   
                <b-col cols="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="SOP Policy"
                      rules="required"
                    >
                <div class="form-check form-check-inline" style="font-size:11px">
                        <input
                          v-model="sp1"
                          type="checkbox"
                          class="form-check-input"
                          true-value="yes"
                          false-value=""
                          id="sp1"
                          v-b-modal.modal-10
                        />
                        I agree to &nbsp;<b-link v-b-modal.modal-10 >
                         Driver SOP Policy
                        </b-link>
                      </div>
                      <br />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                          <!-- Data Privacy Policy -->      
                <b-col cols="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Data Privacy Policy"
                      rules="required"
                    >
            <div class="form-check form-check-inline" style="font-size:11px">
                        <input
                          v-model="dpp1"
                          type="checkbox"
                          class="form-check-input"
                          true-value="yes"
                          false-value=""
                          id="dpp1"
                          v-b-modal.modal-13
                        />
                        I agree to &nbsp;<b-link v-b-modal.modal-13 >
                    Data Privacy Policy
                        </b-link>
                      </div>
                      <br />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>  
                 <!--  Privacy Terms Policy -->  
                <b-col cols="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Privacy Policy"
                      rules="required"
                    >
           <div class="form-check form-check-inline" style="font-size:11px">
                        <input
                        id="pp1"
                          v-model="pp1"
                          type="checkbox"
                          class="form-check-input"
                          true-value="yes"
                          false-value=""
                          v-b-modal.modal-11
                        />
                        I agree to &nbsp;<b-link v-b-modal.modal-11>
                       Privacy Terms Policy
                        </b-link>
                      </div>
                      <br />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                  <!-- Refund Policy -->  
                <b-col cols="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Refund Policy"
                      rules="required"
                    >
     <div class="form-check form-check-inline" style="font-size:11px">
                        <input
                        id="rp1"
                          v-model="rp1"
                          type="checkbox"
                          class="form-check-input"
                          true-value="yes"
                          false-value=""
                          v-b-modal.modal-12
                        />
                        I agree to &nbsp;<b-link v-b-modal.modal-12>
                          Refund Policy
                        </b-link>
                      </div>
                      <br />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

        
                <!-- Rules & Conduct Policy -->
                          <b-col cols="12">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Rules & Conduct Policy"
                      rules="required"
                    >
                      <div class="form-check form-check-inline" style="font-size:11px">
                        <input
                        id="rcp1"
                          v-model="rcp1"
                          type="checkbox"
                          class="form-check-input"
                          true-value="yes"
                          false-value=""
                          v-b-modal.modal-14
                        />
                        I agree to &nbsp;<b-link v-b-modal.modal-14 >
                Rules & Conduct Policy
                        </b-link>
                      </div>
                      <br />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col> 
              </b-row>
            </div>
            <div class="col" style="display: flex; justify-content: center">
              <b-button v-if="!loading" type="submit" variant="danger"> Continue </b-button>
              <b-button v-if="loading" type="button" variant="info"> Please wait.. </b-button>
            </div>
          </b-form>
        </validation-observer>

        <p class="text-center mt-2">
          <b-link :to="{ name: 'login' }">
            <feather-icon icon="ChevronLeftIcon" /> Back to login
          </b-link>
        </p>
      

      <!-- MODALS   -->
            <!-- SOP Policy  -->
      <b-modal
        id="modal-10"
        content-class="policy-modal"
        ok-only
        ok-title="Agree"
        size="lg"
        no-close-on-backdrop
        scrollable
        @ok="agree('sp1')"
      >
        <b-card-text>
          <div class="container">
            <sop-policy />
          </div>
        </b-card-text>
      </b-modal>
         <!-- Golog Terms -->
      <b-modal
        id="modal-11"
        content-class="policy-modal"
        ok-only
        ok-title="Agree"
        size="lg"
        no-close-on-backdrop
        scrollable
       @ok="agree('pp1')"
      >
        <b-card-text>
          <div class="container">
            <golog-terms />
          </div>
        </b-card-text>
      </b-modal>
 <!-- Refund Policy -->
      <b-modal
        id="modal-12"
        content-class="policy-modal"
        ok-only
        ok-title="Agree"
        size="lg"
        no-close-on-backdrop
        scrollable
        @ok="agree('rp1')"
      >
        <b-card-text>
          <div class="container">
            <refund-policy />
          </div>
        </b-card-text>
      </b-modal>
       <!-- Data Policy -->
      <b-modal
        id="modal-13"
        content-class="policy-modal"
        ok-only
        ok-title="Agree"
        size="lg"
        no-close-on-backdrop
        scrollable
        @ok="agree('dpp1')"
      >
        <b-card-text>
          <div class="container">
            <data-policy />
          </div>
        </b-card-text>
      </b-modal>
             <!-- Data Policy -->
      <b-modal
        id="modal-14"
        content-class="policy-modal"
        ok-only
        ok-title="Agree"
        size="lg"
        no-close-on-backdrop
        scrollable
        @ok="agree('rcp1')"
      >
        <b-card-text>
          <div class="container">
            <rules-policy />
          </div>
        </b-card-text>
      </b-modal>
      </div>
</template>

<script>
import axios from "@axios";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BFormSelect,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BForm,
  BButton,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { integer } from "vee-validate/dist/rules";
import GologTerms from "@/components/GologTerms.vue";
import RefundPolicy from "@/components/RefundPolicy.vue";
import SopPolicy from "@/components/SopPolicy.vue";
import DataPolicy from "@/components/DataPolicy.vue";
import RulesPolicy from "@/components/RulesPolicy.vue";
import vSelect from 'vue-select'
import VueGoogleAutocomplete from "vue-google-autocomplete";

extend("integer", {
  ...integer,
  message: "Please enter numbers only",
});
export default {
  components: {
    VueGoogleAutocomplete,
    vSelect,
    GologTerms,
    SopPolicy,
    RefundPolicy,
DataPolicy,
    VuexyLogo,
    RulesPolicy,
    // BSV
    BCard,
    BFormSelect,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    // validations
    ValidationProvider,
    ValidationObserver,
    BFormCheckboxGroup,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      loading: false,
      value: [],
      sp1: null,
      dpp1: null,
      pp1: null,
      rp1:null,
      rcp1:null,
      options: [
        { text: "I agree to the Golog Terms and Condition", value: "first" },
        { text: "I agree to Refund Policy", value: "second" },
        {
          text: "I agree all the above information are valid",
          value: "fourth",
        },
        { text: "I agree to SOP policy", value: "third" },
      ],

      form: {
          type: 0,
          state: 'Perlis',
          country: 'Malaysia',
          company_registration: 'Not Set',
          address1: null,
          address2: null,
          area : null,
          state : null,
          country : null,
          postcode : null

      },
      stateOption: [
        { text: 'Kuala Lumpur', value:  'Kuala Lumpur'}, 
        { text: 'Perlis', value:  'Perlis'}, 
        { text: 'Kedah' ,value:  'Kedah'}, 
        { text: 'Pulau Pinang', value:  'Pulau Pinang' }, 
        { text: 'Perak', value:  'Perak' },
        { text: 'Pahang', value:  'Pahang' }, 
        { text: 'Kelantan', value:  'Kelantan' }, 
        { text: 'Terengganu', value:  'Terengganu' }, 
        { text: 'Selangor', value:  'Selangor' },
        { text: 'Negeri Sembilan', value:  'Negeri Sembilan' },
        { text: 'Melaka', value:  'Melaka' }, 
        { text: 'Johor', value:  'Johor' }, 
        { text: 'Sabah', value:  'Sabah' }, 
        { text: 'Sarawak', value:  'Sarawak' }
      ],
      // validation rules
      required,
      email,
    };
  },
  computed: {
    // state() {
    //   return this.first && this.second && this.fourth && this.fifth && this.six;
    // },
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    validationForm2() {
       let self = this;       
       const formData = new FormData();
          for (const key in self.form) {
            formData.append(key, self.form[key]);
          }
      this.$refs.registerFormInd.validate().then((success) => {
        if (success) {
            self.loading = true;
            this.$http.post(`${this.$appURL}smarttruck_register`, formData)
              .catch((err) => {
                self.loading = false;
              })
              .then((response) => {
                self.loading = false;
                if (response.data?.status) {
                  self.form = {};
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Account Created",
                      icon: "EditIcon",
                      variant: "success",
                    },
                  });
                 window.location.href = '/successful'
                }
              });
        }
      });
    },
    agree(elementId) {
      var checkbox = document.getElementById(elementId)
      checkbox.checked = true

      switch (elementId) {
        case 'sp1':
            this.sp1 = "yes"
          break;
        case 'pp1':
            this.pp1 = "yes"
          break;
        case 'dpp1':
            this.dpp1 = "yes"
          break;
        case 'rcp1':
            this.rcp1 = "yes"
          break;
        default:
            this.rp1 = "yes"
          break;
      }

      console.log(checkbox)
    },
    getAddressData(address, place, id){
      self = this;
      console.log(place)
      console.log(address)

      var address_1 = ``;
      if(address.hasOwnProperty('street_number')){ address_1+=address.street_number}
      if(address.hasOwnProperty('route')) {address_1+=' '+address.route}

      self.form.address1 = address_1
      self.form.postcode = (address.hasOwnProperty('postal_code')) ? address.postal_code : null
      self.form.area = (address.hasOwnProperty('locality')) ? address.locality : null
      self.form.country = address.country
      
      self.form.address2 = place?.vicinity
      self.lat = address?.latitude
      self.lng = address?.longitude

      const getstate = (address.hasOwnProperty('administrative_area_level_1'))?address.administrative_area_level_1 : null

      var setArea = self.stateOption.filter((state)=> state.value === getstate)

      if(!setArea){
        self.stateOption.push({
          text: getstate,
          value: getstate
        });
      }
      self.form.state = getstate

    }
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/pages/page-auth.scss';
.policy-modal header{
  display: none;
}
.policy-modal footer{
  justify-content: center;
}
</style>
<style scoped>
  .auth-wrapper.auth-v1 .auth-inner {
  max-width: 600px;
}
</style>
