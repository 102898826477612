<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Register v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <h1 class="brand-text text-primary">
            Sign Up
          </h1>
        </b-link>

        <!-- form -->
        <b-tabs content-class="mt-1">
          <!-- This tabs content will always be mounted -->
          <b-tab title="Company Driver">
            <Company />
          </b-tab>

          <!-- This tabs content will not be mounted until the tab is shown -->
          <!-- and will be un-mounted when hidden -->
          <b-tab
            title="Individual Driver (Coming Soon)"
            lazy
            disabled
          >
            <Individual />
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>

</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import Company from './components/Company.vue'
import Individual from './components/Individual.vue'

export default {
  components: {
    BTabs,
    BTab,
    Company,
    Individual,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.policy-modal header{
  display: none;
}
.policy-modal footer{
  justify-content: center;
}
</style>
<style scoped>
  .auth-wrapper.auth-v1 .auth-inner {
  max-width: 600px;
}
</style>
